import { createGlobalStyle } from 'styled-components';

const ResetCss = createGlobalStyle`
  /* stylelint-disable */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    box-sizing: border-box;
    vertical-align: baseline;
    text-decoration: none;
    color: inherit;
  }
  /* stylelint-enable */

  html {
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  button {
    border: none;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    color: #444;
    font-size: 1.4rem;
    line-height: 1;
    overflow-x: hidden;
  }

  ol, ul {
    list-style: none;
    list-style-type: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  svg {
    vertical-align: top;
    fill: currentColor;
  }

  a {
    color: #441EF7;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default ResetCss;